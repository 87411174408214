import React, { useEffect, useState } from "react";
import Footer from "../components/Footer.js";
import Navbar from "../components/Navbar.js";

// import api
import { getCMSPage } from "../api/users.js";

// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

export default function Privacy(props) {

  const [content, setContent] = useState("");

  // function
  const fetchCmsPage = async () => {
    try {
      const { status, result } = await getCMSPage("privacy_policy");
      // console.log("result", result);
      if (status == "success") {
        setContent(result);
        // console.log("content", content);
        document.title = result.title;
        const el = document.querySelector("meta[name='description']");
        el.setAttribute("content", result.metadescription);
        document.keywords = el;
        // console.log("el", el);
        const els = document.querySelector("meta[name='keywords']");
        els.setAttribute("content", result.metakeywords);
        document.description = els;
        // console.log("", els);
      }
    } catch (err) { }
  };

  useEffect(() => {
    fetchCmsPage();
  }, []);

  return (
    <div>
      <ScrollToTopOnMount />
      <Navbar />
      <div className="innerpages_wrapper">
        <div className="container">

          <div className="dash_wrapper">
            <div className="row">
              <div
                dangerouslySetInnerHTML={{
                  __html: content && content.content,
                }}
              />

            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
