import React, { useState, useEffect, useContext } from "react";
import DataTable from "react-data-table-component";
import { useTranslation } from "react-i18next";

import CurrencyFormat from 'react-currency-format';
import SocketContext from "../../context/SocketContext";

//import api
import { getClosedOrder } from "../../api/tradeAction";

//import lib
import { dateTimeFormat } from "../../lib/dateTimeHelper";
import { toFixedDown, truncateDecimals } from "../../lib/roundOf";


const ClosedOrders = () => {
    const { t, i18n } = useTranslation()

    // History Table Data
    const tansaction_columns = [
        {
            name: t("PAIR_NAME"),
            width: "120px",
            selector: (row) => row?.pairName,
        },
        {
            name: t("BUYSELL"),
            width: "100px",
            selector: (row) => row?.direction == "closed_long" ? <span className="txt_green" >{t("BUY")}</span> : <span className="txt_red" >{t('SELL')}</span>,
        },
        {
            name: t("QUANTITY"),
            width: "100px",
            cell: (record) => {
                return (
                    <CurrencyFormat displayType={'text'} thousandSeparator={true} value={truncateDecimals(record?.quantity, 2)} />
                )
            }
        },
        {
            name: t("ENTRY_PRICE"),
            width: "150px",
            selector: (row) => <CurrencyFormat displayType={'text'} thousandSeparator={true} value={truncateDecimals(row?.entryPrice, 2)} />,
        },
        {
            name: t("OPEN_TIME"),
            width: "200px",
            selector: (row) => dateTimeFormat(row?.openAt),
        },
        {
            name: t("CLOSE_PRICE"),
            width: "150px",
            selector: (row) => <CurrencyFormat displayType={'text'} thousandSeparator={true} value={truncateDecimals(row?.exitPrice, 2)} />,
        },
        {
            name: t("CLOSE_TIME"),
            width: "200px",
            selector: (row) => dateTimeFormat(row?.closedAt),
        },
        // {
        //     name: "Liquidation Price",
        //     width: "150px",
        //     selector: (row) => <CurrencyFormat displayType={'text'} thousandSeparator={true} value={truncateDecimals(row?.liquidationPrice, 2)} />,
        // },
        {
            name: t("PNL"),
            width: "120px",
            cell: (record) => {
                return (
                    <span className={record?.pnl < 0 ? "txt_red" : "txt_green"}> <CurrencyFormat displayType={'text'} thousandSeparator={true} value={truncateDecimals(record?.pnl, 2)} /></span>
                )
            }
        },
        {
            name: t("REASON"),
            width: "150px",
            selector: (row) => row?.reason == "Low Margin" ? <>{t("LOW_MARGIN")}</> : <>{t("CLOSED_BY_USER")}</>,
        },
        // {
        //     name: "SL/TP",
        //     width: "200px",
        //     cell: (record) => {
        //         let slPrice = record?.slPrice ? truncateDecimals(record?.slPrice, 2) : 0
        //         let tpPrice = record?.tpPrice ? truncateDecimals(record?.tpPrice, 2) : 0
        //         return (
        //             <>
        //                 <CurrencyFormat displayType={'text'} thousandSeparator={true} value={slPrice} />&nbsp;/&nbsp;
        //                 <CurrencyFormat displayType={'text'} thousandSeparator={true} value={tpPrice} />

        //             </>
        //         )
        //     }
        // },
    ];


    //state
    const [data, setData] = useState([])
    const [count, setCount] = useState()
    const [pageDoc, setPageDoc] = useState({ page: 1, limit: 20 });
    const { page, limit } = pageDoc;

    const socketContext = useContext(SocketContext);



    //function
    const fetchTradeHistory = async () => {
        const { status, loading, message, result, errors } = await getClosedOrder({ page, limit })
        if (status == 'success') {
            setData(result?.data);
            setCount(result?.count)
        }
    }


    useEffect(() => {
        fetchTradeHistory()
        socketContext.socket.on("perpetualClosedPnL", (result) => {
            setData(result?.data);
            setCount(result?.count)
        });
    }, [page, limit])


    const customPaginationOptions = {
        rowsPerPageText: t('ROWS_PER_PAGE'),
        rangeSeparatorText: t('OF'),
      };


    return (
        <>
            <div className="row">
                <div className="col-xl-12">
                    <div className="dash_box">
                        <div className="primary_datatable">
                            <DataTable
                                columns={tansaction_columns}
                                data={data}
                                pagination
                                paginationServer
                                paginationTotalRows={count}
                                paginationPerPage={limit}
                                paginationRowsPerPageOptions={[20, 30, 40, 50]}
                                onChangePage={(page) =>

                                    setPageDoc({ ...pageDoc, ...{ page: page } })
                                }
                                onChangeRowsPerPage={(limit) =>
                                    setPageDoc({ ...pageDoc, ...{ page: 1, limit: limit } })
                                }
                                noDataComponent={t('THERE_NO_RECORD')}
                                paginationComponentOptions={customPaginationOptions}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ClosedOrders;