import React, { useEffect, useState, useContext } from "react";
import CurrencyFormat from 'react-currency-format';
import { Dropdown, Image, Card, Accordion } from "react-bootstrap";
import "rc-slider/assets/index.css";
import { useNavigate, useParams, useLocation, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import SocketContext from "../../context/SocketContext";
import { Scrollbars } from 'react-custom-scrollbars-2';
import { useTranslation } from "react-i18next";

// import components
import OpenPosition from "./OpenPosition"
import TradeviewChart from "./TradeviewChart";
import ClosedOrders from "./ClosedOrder";
import Orderplace from "./orderplace";


//import api
import {
  getPairList, setPairList, setMarketPrice, setTradePair,
  getAssetByCurrency, setUserFirstCurrency, setUserSecondCurrency,
  getMarketPrice, getAllOpenPosition, updateTradeAsset, closeAllOrder
} from "../../api/tradeAction";


// lib
import isEmpty from "../../lib/isEmpty"
import { unrealizedPnL } from "../../lib/bybit";
import { toFixedDown, truncateDecimals } from "../../lib/roundOf";
import { userWalletList } from "../../api/walletAction";
import { toastAlert } from "../../lib/toastAlert";
import Slider from "react-slick";
import OrderBook from "./OrderBook";
import TopStories from "./TopStories";
import EconomicCalender from "./EconomicCalender";

const assetType = ['Crypto', "Currencies", "Stocks", "Indices", "Commodities"]

const Trade = () => {

  const history = useNavigate()
  const { tikerRoot } = useParams()
  const dispatch = useDispatch()
  const location = useLocation();
  const socketContext = useContext(SocketContext);
  const { t, i18n } = useTranslation()

  //state
  const [activeTradeMethod, setActiveTradeMethod] = useState("buy");
  const [pairLists, setPairLists] = useState([]);
  const [totalpair, setTotalPair] = useState([])
  const [pairdatas, setPairdatas] = useState([])
  const [CloseAllLoder, setCloseAllLoder] = useState(false);
  const [totalPNL, setTotalPNL] = useState(0)
  const [available, setAvailable] = useState({})
  const [tradedata, setTradeData] = useState([])
  const [currencyData, setCurrency] = useState([])


  //redux
  const pairData = useSelector((state) => state.tradePair);
  const pairListData = useSelector((state) => state.pairList);
  const walletData = useSelector((state) => state.wallet);
  const currency = useSelector((state) => state.currency);
  const priceConversion = useSelector((state) => state.priceConversion);
  const marketPrice = useSelector((state) => state.marketPrice)


  //function
  const handleStakingMethodClick = (button) => {
    setActiveTradeMethod(button);
  };


  const fetchAssetByCurrency = async (spotPairId, type) => {
    try {
      const { status, result } = await getAssetByCurrency(spotPairId);
      if (status == "success") {
        if (type == "firstCurrency") {
          setUserFirstCurrency(result, dispatch);
        } else if (type == "secondCurrency") {
          setUserSecondCurrency(result, dispatch);
        }
      } else {
      }
    } catch (err) {
      console.log(err, 'errro');
    }
  };

  const fetchPairList = async () => {
    try {
      const { status, result } = await getPairList();
      if (status == "success") {
        setPairdatas(result)
        setPairList(result, dispatch);
        if (result && result.length > 0) {
          if (isEmpty(tikerRoot)) {
            let pair = `${result[0].firstCurrencySymbol}_${result[0].secondCurrencySymbol}`;
            history("/trade/" + result[0].tikerRoot);
            await fetchAssetByCurrency(
              result[0].firstCurrencyId,
              "firstCurrency"
            );
            await fetchAssetByCurrency(
              result[0].secondCurrencyId,
              "secondCurrency"
            );
            await setMarketPrice(result[0], dispatch);
            await setTradePair(result[0], dispatch);
            // getMarketPrice(result[0]._id, dispatch);
          } else {
            let currency = tikerRoot.split("_");
            let pairDetail = result.find(
              (el) =>
                el.tikerRoot == tikerRoot
            );
            // console.log("pairDetail", pairDetail);
            if (isEmpty(pairDetail && pairDetail)) {
              history("/404");
            }
            await fetchAssetByCurrency(
              pairDetail.firstCurrencyId,
              "firstCurrency"
            );
            await fetchAssetByCurrency(
              pairDetail.secondCurrencyId,
              "secondCurrency"
            );
            // // getMarketPrice(pairDetail._id, dispatch);
            await setMarketPrice(pairDetail, dispatch);
            await setTradePair(pairDetail, dispatch);
          }
        }
      } else {
      }
    } catch (err) {
      console.log("errrrrrrrr", err);
    }
  };


  const handlePairChange = async (datas) => {
    let pair = `${datas.firstCurrencySymbol}_${datas.secondCurrencySymbol}`;
    history("/trade/" + datas.tikerRoot);
    await fetchAssetByCurrency(datas.firstCurrencyId, "firstCurrency");
    await fetchAssetByCurrency(datas.secondCurrencyId, "secondCurrency");
    await setMarketPrice(datas, dispatch);
    await setTradePair(datas, dispatch);
    // socketContext.socket.off("marketPrice");
  };

  const handleAsset = async () => {
    try {
      let totalAmount = 0;
      let lockedBal = 0;
      let tempArr = [...walletData];
      let currencyArr = [];
      currency?.length >= 0 &&
        currency.map((item, index) => {
          let PriceCnv = priceConversion.find(
            (el) =>
              el.baseSymbol == item.currencySymbol && el.convertSymbol == "USD"
          );

          currencyArr.push({
            image: item.image,
            coin: item.currencySymbol,
            decimals: item.decimals,
            gateWay: item.gateWay,
            USDValue: !isEmpty(PriceCnv?.convertPrice) ? parseFloat(PriceCnv.convertPrice) : 1
          })

          let pairIndex =
            tempArr &&
            tempArr.findIndex((el) => {
              return el._id == item._id;
            });
          if (pairIndex >= 0 && !isEmpty(pairIndex)) {
            tempArr[pairIndex] = {
              ...tempArr[pairIndex],
              ...{
                lockedBal: !isEmpty(PriceCnv?.convertPrice)
                  ? parseFloat(tempArr[pairIndex].lockedBal) *
                  parseFloat(PriceCnv.convertPrice)
                  : parseFloat(tempArr[pairIndex].lockedBal),
                USDValue: !isEmpty(PriceCnv?.convertPrice)
                  ? parseFloat(tempArr[pairIndex].stakeBal - tempArr[pairIndex].lockedBal) *
                  parseFloat(PriceCnv.convertPrice)
                  : parseFloat(tempArr[pairIndex].stakeBal - tempArr[pairIndex].lockedBal),
              },
            };
            totalAmount += tempArr[pairIndex].USDValue;
            lockedBal += tempArr[pairIndex].lockedBal;
          }
        });
      setCurrency(currencyArr);

      let result = {
        totalBal: truncateDecimals(totalAmount, 2),
        lockedBal: truncateDecimals(lockedBal, 2),
      }
      setAvailable(result)

    } catch (err) {
      console.log("err:------ ", err);
    }
  };


  const fetchTradeHistory = async () => {
    const { status, result, } = await getAllOpenPosition({})
    if (status == 'success') {
      // console.log(result)
      setTradeData(result)
      // let totalPnl = 0
      // result?.result && result?.result?.length > 0 && result.result.map((item, key) => {
      //   let price = item.side == "sell" ? item.askPrice : item.bidPrice;
      //   let pnl = unrealizedPnL({
      //     entryPrice: item.entryPrice,
      //     quantity: item.quantity,
      //     lastPrice: price,
      //     buyorsell: item.side,
      //   })
      //   totalPnl += pnl
      // })
      // setTotalPNL(truncateDecimals(totalPnl, 2))
    }
  }


  const handleCloseAll = async () => {
    try {
      setCloseAllLoder(true)
      const { status, loading, message, result, errors } = await closeAllOrder()
      if (status) {
        setCloseAllLoder(false)
        toastAlert("success", t(message), "login");
      } else {
        setCloseAllLoder(false)
        toastAlert("error", t(message), "login");
      }
    } catch (err) {
      setCloseAllLoder(false)
      console.log(err, 'errorr')
    }
  }




  useEffect(() => {
    let pathname = location.pathname;

    if (!isEmpty(pairData)) {
      if (pathname == "/trade/" + pairData.tikerRoot) {
        // console.log("1111111");
        // socket
        let allPairPriceUpdate = [...pairdatas]
        socketContext.socket.on("marketPrice", (result) => {
          if (result.pairId == pairData.pairId) {
            setMarketPrice(result.data, dispatch);
            let tempPairList = pairLists;
            let pairIndex = tempPairList.findIndex((el) => el._id == result.pairId);
            if (pairIndex >= 0) {
              tempPairList[pairIndex] = { ...tempPairList[pairIndex], ...{ markPrice: 0, change: result.data.change } }
              setPairLists(tempPairList);
            }
          }
          if (result) {
            let pairIndex = allPairPriceUpdate.findIndex((el) => {
              return el._id.toString() == result.pairId.toString()
            });
            if (pairIndex >= 0) {
              allPairPriceUpdate[pairIndex] = { ...allPairPriceUpdate[pairIndex], ...{ bidPrice: result?.data?.bidPrice, askPrice: result?.data?.askPrice, markPrice: result?.data?.markPrice, change: result?.data?.change } };
              // setPairList(tempPairList ,dispatch)
              setTotalPair(allPairPriceUpdate);
            }
          }
        });
        return () => {
          socketContext.socket.off("marketPrice");
        };
      }
    }

  }, [pairData]);



  useEffect(() => {
    if (pairListData && pairListData.length > 0) {
      setPairLists(pairListData); setTotalPair(pairListData)
    }
  }, [pairListData]);



  useEffect(() => {
    fetchPairList();
  }, [])


  useEffect(() => {
    fetchTradeHistory()
    handleAsset()
    socketContext.socket.on("updateWallet", (result) => {
      // console.log(result, '888888888')
      dispatch(userWalletList(result?.walletData))
    });
  }, [currency, walletData, priceConversion]);

  useEffect(() => {
    if (tradedata && tradedata.length > 0 && !isEmpty(totalpair)) {
      let totalPnl = 0
      tradedata && tradedata?.length > 0 && tradedata.map((item, key) => {
        let filter = totalpair && totalpair.find((el) => el._id.toString() == item.pairId.toString())
        // console.log(item, 'filterfilter')
        let price = item.side == "sell" ? filter?.askPrice : filter?.bidPrice;
        let pnl = unrealizedPnL({
          entryPrice: item.entryPrice,
          quantity: item.quantity,
          lastPrice: price,
          buyorsell: item.side,
        })
        // let pnl = pAndL + filter?.spread
        let totalSpread = parseFloat(item?.userId?.userSpread) + parseFloat(filter?.spread)
        totalPnl += pnl + (totalSpread * item.quantity)
      })
      setTotalPNL(truncateDecimals(totalPnl, 2))
    }

  }, [marketPrice, totalpair, tradedata]);

  let Equity = parseFloat(available.totalBal) + parseFloat(totalPNL)


  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    margin: 30,
    responsive: [
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 3,
        }
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2,
        }
      },
      {
        breakpoint: 400,
        settings: {
          slidesToShow: 1,
        }
      },
    ]
  };


  return (
    <div className="dash_wrapper">
      <div className="d-flex gap-1 align-items-center justify-content-end mt-1 mb-3">
        <a href="/protrade">
          <button type="button" className="primary_btn">
            {t("PRO_TRADE")}
          </button>
        </a>
      </div>
      <div className="row copy_trade_row">
        <div className="col-lg-12">
          <div className="row" >
            <div className="col-lg-5  m-auto" >
              <Slider {...settings}>
                {currencyData && currencyData && currencyData.length > 0 && currencyData.map((item, i) => {
                  if (item.gateWay == "local")
                    return (
                      <div>
                        <div className="crypto_box">
                          <img src={item.image} alt="Image" className="img-fluid" width={38} height={38} />
                          <div>
                            <p>{item.coin}</p>
                            <span>$ {toFixedDown(item.USDValue, item.decimals)}</span>
                          </div>
                        </div>
                      </div>
                    )
                })}
              </Slider>
            </div>
            <div className="col-lg-7 m-auto" >
              <div className="dash_box trade_top_info mb-0 mt-3 mt-lg-0">
                <div>
                  <label>{t("PNL")}</label>
                  <label className={totalPNL >= 0 ? "txt_success" : "txt_red"} >$ <CurrencyFormat displayType={'text'} thousandSeparator={true} value={totalPNL} /></label>
                </div>
                <div>
                  <label>{t("CASH_BALANCE")}</label>
                  <label>$ <CurrencyFormat displayType={'text'} thousandSeparator={true} value={available?.totalBal} /></label>
                </div>
                <div>
                  <label>{t("EQUITY")}</label>
                  <label className={Equity >= 0 ? "txt_success" : "txt_red"} >$ <CurrencyFormat displayType={'text'} thousandSeparator={true} value={truncateDecimals(Equity, 2)} /></label>
                </div>
                <div>
                  <label>{t("USED_MARGIN")}</label>
                  <label>$ <CurrencyFormat displayType={'text'} thousandSeparator={true} value={available?.lockedBal} /></label>
                </div>
                <div>
                  <label>{t("AVAILABLE_MARGIN")}</label>
                  <label>$ <CurrencyFormat displayType={'text'} thousandSeparator={true} value={available?.totalBal} /></label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-2">
          <div className="dash_box" style={{ height: "98%", padding: "20px" }}>

            <div className="coins_box">
              <Accordion defaultActiveKey="0" flush >
                {
                  assetType && assetType.length > 0 && assetType.map((asset, index) => {
                    let assetType = asset == "Currencies" ? 'currency' : asset.toLowerCase()

                    // console.log(asset, '------assetType')

                    if (asset == 'Crypto') {
                      asset = t("CRYPTO")
                    } else if (asset == 'Currencies') {
                      asset = t('CURRENCIES')
                    } else if (asset == "Commodities") {
                      asset = t('COMMODITIES')
                    } else if (asset == "Stocks") {
                      asset = t('STOCKS')
                    } else if (asset == 'Indices') {
                      asset = t("INDICES")
                    }

                    return (

                      <Accordion.Item eventKey={index.toString()} key={index} alwaysOpen={false}>
                        <Accordion.Header>{asset}</Accordion.Header>
                        <Accordion.Body>
                          <Scrollbars style={{ widthy: "100%", height: "300px" }} className="verticalScroll">
                            <div className="menu-list">
                              {pairLists.map((option, i) => {
                                let symbole = option.firstCurrencySymbol
                                if (option.type == "currency") symbole = `${option.firstCurrencySymbol}/USD`
                                // console.log(option.type,'------option.type')

                                if (assetType == option.type)
                                  return <div onClick={() => handlePairChange(option)}><Image
                                    src={option.image}
                                    width={23}
                                    height={23}
                                  />{" "} {symbole}</div>
                              })
                              }
                            </div>
                          </Scrollbars>
                        </Accordion.Body>
                      </Accordion.Item>
                    )
                  })
                }
              </Accordion>
              {/* {
                assetType && assetType.length > 0 && assetType.map((asset, index) => {
                  let assetType = asset == "Currencies" ? 'currency' : asset.toLowerCase()
                  return (
                    <Dropdown
                      className="image_dropdown_wrapper"
                      drop="down"
                    >
                      <Dropdown.Toggle
                        id="dropdown-basic"
                        className="image_dropdown"
                      >
                        {asset}
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        {pairLists.map((option, i) => {
                          let symbole = option.firstCurrencySymbol
                          if (option.type == "currency") symbole = `${option.firstCurrencySymbol}/USD`
                          if (assetType == option.type)
                            return (
                              <Dropdown.Item
                                onClick={() => handlePairChange(option)}
                              >
                                <Image
                                  src={option.image}
                                  width={38}
                                  height={38}
                                />{" "}
                                {symbole}
                              </Dropdown.Item>
                            )
                        })}
                      </Dropdown.Menu>
                    </Dropdown>
                  )
                })
              } */}
            </div>
          </div>
        </div>
        <div className="col-lg-10">
          <div className="row">
            <div className="col-lg-12 col-xl-4 d-flex">
              <div className="w-100 dash_box">
                <div className="staking_calc trade_buy">
                  <div className="staking_method_btngrp">
                    <button
                      className={
                        activeTradeMethod === "buy"
                          ? "trade_buy trade_btn active"
                          : "trade_buy trade_btn"
                      }
                      onClick={() => handleStakingMethodClick("buy")}
                    >
                      {t("BUY")}
                    </button>
                    <button
                      className={
                        activeTradeMethod === "sell"
                          ? "trade_sell trade_btn active"
                          : "trade_sell trade_btn"
                      }
                      onClick={() => handleStakingMethodClick("sell")}
                    >
                      {t("SELL")}
                    </button>
                  </div>
                  <div class="">
                    <div class="form-group">
                      <label class="form-label">{t("ASSET")}</label>

                      <Dropdown
                        className="image_dropdown_wrapper"
                        drop="down"
                      >
                        <Dropdown.Toggle
                          id="dropdown-basic"
                          className="image_dropdown"
                        >
                          <Image
                            src={pairData.image}
                            width={38}
                            height={38}
                          />
                          {pairData.firstCurrencySymbol}/{pairData.secondCurrencySymbol}
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                          {pairLists.map((option, i) => {
                            let symbole = `${option.firstCurrencySymbol}/${option.secondCurrencySymbol}`
                            // if (option.type == "currency") symbole = `${option.firstCurrencySymbol}/USD`
                            return (
                              <Dropdown.Item
                                onClick={() => handlePairChange(option)}
                              >
                                <Image
                                  src={option.image}
                                  width={38}
                                  height={38}
                                />{" "}
                                {symbole}
                              </Dropdown.Item>
                            )
                          })}
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                    <Orderplace type={activeTradeMethod} refetch={fetchTradeHistory} handleAsset={handleAsset} pairData={pairData} />
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-12 col-xl-8 d-flex">
              <div className="w-100 dash_box">
                <div className='tradingview-widget-container'>
                  {/* <Chart /> */}
                  <TradeviewChart />
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-4 d-flex">
              <OrderBook />
            </div>
            <div className="col-lg-4 d-flex">
              <div class="w-100 dash_box">
                <EconomicCalender />
                {/* <img
                  alt="img"
                  className="img-fluid"
                  src={require("../../assets/images/chart1.png")}
                /> */}
              </div>
            </div>
            <div className="col-lg-4 d-flex">
              <div class="w-100 dash_box">
                <TopStories />
                {/* <img
                alt="img"
                className="img-fluid"
                src={require("../../assets/images/chart2.png")}
              /> */}
              </div>
            </div>
          </div>
          <div className="dash_wrapper">
            <div className="staking_top_flex">
              <ul
                class="nav nav-pills custom_nav_tabs w-100"
                id="pills-tab"
                role="tablist"
              >
                <li class="nav-item" role="presentation">
                  <button
                    class='nav-link active'
                    id="pills-OpenPosition-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-OpenPosition"
                    type="button"
                    role="tab"
                    aria-controls="pills-OpenPosition"
                    aria-selected="true"
                  >
                    {t("OPEN_POSITIONS")}
                  </button>
                </li>
                <li class="nav-item" role="presentation">
                  <button
                    class='nav-link'
                    id="pills-trade-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-trade"
                    type="button"
                    role="tab"
                    aria-controls="pills-trade"
                    aria-selected="false"
                  >
                    {t("CLOSED_POSITIONS")}
                  </button>
                </li>
                {
                  tradedata.length > 0 &&
                  <li className="ms-lg-auto" ><button class="nav-link active primary_btn" disabled={CloseAllLoder} onClick={handleCloseAll} > {t("CLOSE_ALL_TRADES")}</button></li>
                }
              </ul>
            </div>
            <div class="tab-content" id="pills-tabContent">
              <div
                class="tab-pane fade show active"
                id="pills-OpenPosition"
                role="tabpanel"
                aria-labelledby="pills-OpenPosition-tab"
              >
                <OpenPosition totalpair={totalpair} refetch={fetchTradeHistory} handleAsset={handleAsset} />
              </div>
              <div
                class="tab-pane fade"
                id="pills-trade"
                role="tabpanel"
                aria-labelledby="pills-trade-tab"
              >
                <ClosedOrders />
              </div>
            </div>
          </div>
        </div>
      </div>

    </div >
  );
};

export default Trade;
