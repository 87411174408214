// import lib
import isEmpty from '../../lib/isEmpty';

const validation = (value, callFrom) => {
    if (callFrom == 'createTicket') {
        return createTicket(value)
    } else if (callFrom == 'replyMsg') {
        return replyMsg(value)
    }
}

export const createTicket = value => {
    let errors = {};


    if (isEmpty(value.categoryId) || value.categoryId == "") {
        errors.categoryId = "REQUIRED"
    }

    if (isEmpty(value.message)) {
        errors.message = "REQUIRED"
    }


    return errors;
}


export const replyMsg = value => {
    let errors = {};
    let imageFormat = /\.(jpg|JPG|jpeg|JPEG|png|PNG|pdf)$/;


    if (isEmpty(value.message)) {
        errors.message = "REQUIRED"
    }

    if (!isEmpty(value.image)) {
        if (value.image.size > 1000000) {
            errors.image = "TOO_LARGE"
        } else if (!imageFormat.test(value.image.name)) {
            errors.image = "INVALID_IMAGE"
        }
    }

    return errors;
}

export default validation;