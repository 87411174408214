import isEmpty from "../../lib/isEmpty";

export const StakingValidation = (value) => {
    let errors = {};

    if (isEmpty(value.price)) {
        errors.price = "AMOUNT_REQUIRED"
    } else if (isNaN(value.price)) {
        errors.price = "VALID_AMOUNT"
    }


    return errors;
}