import React, { useEffect, useState } from 'react';
import DataTable from "react-data-table-component";
import { useTranslation } from "react-i18next";

// import api
import { getLoginHistory } from '../../api/users';

//import lib
import { dateTimeFormat } from "../../lib/dateTimeHelper";



const LoginHistory = () => {


  const { t, i18n } = useTranslation()


  // state
  const [data, setData] = useState([]);
  const [count, setCount] = useState(0);
  const [pageDoc, setPageDoc] = useState({ 'page': 1, 'limit': 20 })
  const { page, limit } = pageDoc;

  //function
  const getData = async () => {
    try {
      const { status, result } = await getLoginHistory({ page, limit });
      if (status === 'success') {
        setData(result.data)
        setCount(result.count)
      }
    } catch (err) {
      console.log(err, 'errro')
    }
  }

  useEffect(() => {
    getData()
  }, [page, limit]);



  const columns = [
    {
      name: t("DATE"),
      selector: (row) => dateTimeFormat(row.createdAt),
      width: "35%"
    },
    // {
    //   name: "Country Code",
    //   selector: (row) => row.countryCode,
    // },
    // {
    //   name: "Country/Region",
    //   selector: (row) => row.countryName,
    //   cell: (record) => `${record.countryName} / ${record.regionName}`
    // },
    // {
    //   name: "Ip Address",
    //   selector: (row) => row.ipAddress,
    // },
    {
      name: t("BROWSER"),
      selector: (row) => row.broswerName,
      width: "35%"
    },
    {
      name: t("STATUS"),
      selector: (row) => row.status == "Failed" ? t("FAILED") : t("SUCCESS"),
      width: "30%"
    },
  ];

  const customPaginationOptions = {
    rowsPerPageText: t('ROWS_PER_PAGE'),
    rangeSeparatorText: t('OF'),
  };

  return (
    <div className="dash_box">
      <div className="inner_subtitle_wrap inner_subtitle_wrap_flex">
        <h3>{t("LOGIN_ACTIVITY")}</h3>
      </div>
      <div className="primary_datatable">
        <DataTable
          columns={columns}
          data={data}
          pagination
          paginationServer
          paginationTotalRows={count}
          paginationPerPage={limit}
          paginationRowsPerPageOptions={[20, 30, 40, 50]}
          onChangePage={(page) => setPageDoc({ ...pageDoc, ... { ['page']: page } })}
          onChangeRowsPerPage={(limit) => setPageDoc({ ...pageDoc, ... { ['limit']: limit } })}
          noDataComponent={t('THERE_NO_RECORD')}
          paginationComponentOptions={customPaginationOptions}

        />
      </div>
    </div>
  )
}

export default LoginHistory;