import React, { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import CurrencyFormat from 'react-currency-format';
import { useTranslation } from "react-i18next";

// import api
import { getTrnxHistory } from "../../api/walletAction";

// import lib
import { dateTimeFormat } from "../../lib/dateTimeHelper";
import { toFixedDown, truncateDecimals } from "../../lib/roundOf"
import isEmpty from "../../lib/isEmpty";



const TransactionHistory = () => {

  const { t, i18n } = useTranslation()



  // state
  const [data, setData] = useState([]);
  const [loader, setLoading] = useState(true);
  const [count, setCount] = useState(0);
  const [pageDoc, setPageDoc] = useState({ page: 1, limit: 20 });
  const { page, limit } = pageDoc;


  //function
  const getData = async () => {
    try {
      setLoading(true)
      const { status, result } = await getTrnxHistory({ page, limit });
      setLoading(false)
      if (status === "success") {
        setData(result.data);
        setCount(result.count);
      }
    } catch (err) {
      setLoading(false)
      console.log("ERROR", err)
    }
  };

  const columns = [
    {
      name: t("DATE"),
      width: "180px",
      cell: (record) => `${dateTimeFormat(record?.createdAt)}`,
    },
    {
      name: t("COIN"),
      width: "80px",
      selector: (row) => row?.currencySymbol,
    },
    {
      name: t("AMOUNT"),
      width: "150px",
      cell: (record) => {
        return (<CurrencyFormat displayType={'text'} thousandSeparator={true} value={truncateDecimals(record?.amount, 4)} />)
      },
    },
    {
      name: t("USER_ID"),
      width: "100px",
      selector: (row) => row.userCode,
    },
    {
      name: t("TYPE"),
      width: "150px",
      selector: (row) => row.paymentType,
      cell: (record) => {
        let data = record.paymentType

        if (record.paymentType == "coin_deposit") {
          data = t("COIN_DEPOSIT")
        } if (record.paymentType == "coin_withdraw") {
          data = t("COIN_WITHDRAW")
        } if (record.paymentType == "coin-transfer") {
          data = t("COIN_TRANSFER")
        } if (record.paymentType == "Profit") {
          data = t("PROFIT")
        }

        return (
          <>{data}</>
        )
      }
    },
    {
      name: t("STATUS"),
      selector: (row) => row.status,
      width: "150px",
      cell: (record) => {
        let data = record?.status == "pending" ? t("PENDING") : record?.status == "completed" ? t("COMPLETED") : t("REJECTED")
        return (<>
          {data}
        </>)
      }
    },
    {
      name: t("REASON"),
      width: "300px",
      selector: (row) => !isEmpty(row.reason) ? row.reason : "-",
    },
  ];

  useEffect(() => {
    getData();
  }, [page, limit]);


  const customPaginationOptions = {
    rowsPerPageText: t('ROWS_PER_PAGE'),
    rangeSeparatorText: t('OF'),
  };

  return (
    <>
      <div className="dash_box">
        <div className="primary_datatable primary_datatable_chg">
          <DataTable
            columns={columns}
            data={data}
            pagination
            paginationServer
            paginationTotalRows={count}
            paginationPerPage={limit}
            paginationRowsPerPageOptions={[20, 30, 40, 50]}
            onChangePage={(page) =>
              setPageDoc({ ...pageDoc, ...{ page: page } })
            }
            onChangeRowsPerPage={(limit) =>
              setPageDoc({ ...pageDoc, ...{ page: 1, limit: limit } })
            }
            noDataComponent={t('THERE_NO_RECORD')}
            paginationComponentOptions={customPaginationOptions}

          />
        </div>
      </div>
    </>
  );
};

export default TransactionHistory;
