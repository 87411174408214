import isEmpty from "../../lib/isEmpty";
export const ProfileValidation = value => {
    let errors = {};

    let imageFormat = /\.(jpg|JPG|jpeg|JPEG|png|PNG|pdf)$/;

    if (isEmpty(value.firstName)) {
        errors.firstName = "FIRST_NAME_REQUIRED";
    }

    if (isEmpty(value.lastName)) {
        errors.lastName = "LAST_NAME_REQUIRED";
    }

    if (isEmpty(value.phoneCode)) {
        errors.phoneCode = "PHONE_NUMBER_REQUIRED";
    }

    if (isEmpty(value.phoneNo)) {
        errors.phoneCode = "PHONE_NUMBER_REQUIRED";
    }

    if (isEmpty(value.address)) {
        errors.address = "ADDRESS_REQUIRED";
    }

    if (isEmpty(value.country)) {
        errors.country = "COUNTRY_REQUIRED";
    }

    if (isEmpty(value.city)) {
        errors.city = "CITY_REQUIRED";
    }

    if (isEmpty(value.postalCode)) {
        errors.postalCode = "POSTAL_CODE_REQUIRED";
    }

    // if (value.profile && value.profile.size) {
    //     if (value.profile.size > 1000000) {
    //         errors.profile = "Too large"
    //     } else if (!imageFormat.test(value.profile.name)) {
    //         errors.profile = "Please select valid format"
    //     }
    // } else {
    //     errors.profile = "Image field is required";
    // }

    return errors;
}



export const changePwdValidate = value => {
    let errors = {};
    let passwordRegex = /^(?=.*\d)(?=.*[A-Z])(?=.*[a-z])(?=.*\W).{6,18}/g;

    if (isEmpty(value.oldPassword)) {
        errors.oldPassword = "OLD_PASSWORD_REQUIRED";
    }
    if (isEmpty(value.password)) {
        errors.password = "NEW_PASSWORD_REQUIRED";
    } else if (!passwordRegex.test(value.password)) {
        errors.password = "PASSWORD_MIN_MAX";
    } else if (value.password.length > 18) {
        errors.password = "PASSWORD_MIN_MAX";
    }

    if (isEmpty(value.confirmPassword)) {
        errors.confirmPassword = "CONFIRM_PASSWORD_REQUIRED";
    } else if (!isEmpty(value.password) && !isEmpty(value.confirmPassword) && value.password != value.confirmPassword) {
        errors.confirmPassword = "CONFIRM_PASSWORD_MISMATCH";
    } else if (value.oldPassword === value.password) {
        errors.password = "PASSWORD_SAME";
    }

    return errors;
};