import React, { useEffect, useState } from "react";
import logo from "../assets/images/logo.svg";
import logo_dark from "../assets/images/logo_dark.svg";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { useTheme } from "./ThemeContext";
import { useTranslation, Trans } from "react-i18next";


export default function Footer() {

  const { t, i18n } = useTranslation()

  //redux
  const { isAuth } = useSelector((state) => state.auth);

  const siteDoc = useSelector((state) => state.siteSetting)

  const { theme } = useTheme();

  return (
    <footer className="footer">
      <div className="container">
        <div className="footer_panel_top">
          <div className="row">
            <div className="col-lg-9 col-md-12">
              <div className="left-footer-links">
                <div className="row">
                  <div className="col-lg-3 col-md-3 col-sm-6">
                    <div className="footer-links-card">
                      <h3>{t("INSTRUMENTS")}</h3>
                      <Link to="/cms/instruments-crypto">{t("CRYPTO")}</Link>
                      <Link to="/cms/instruments-forex">{t("FOREX")}</Link>
                      <Link to="/cms/instruments-stocks">{t("STOCKS")}</Link>
                      <Link to="/cms/instruments-indices">{t("INDICES")}</Link>
                      <Link to="/cms/instruments-commodities">{t("COMMODITIES")}</Link>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-3 col-sm-6">
                    <div className="footer-links-card">
                      <h3>{t("TRADING")}</h3>
                      <Link to="/">{t("PLATFORM")}</Link>
                      {siteDoc?.walletAccess && <Link to={isAuth == true ? "/wallet" : "/login"}>{t("DEPOSIT")} </Link>}
                      <Link to={isAuth == true ? "/" : "/register"}>{t("OPEN_ACCOUNT")}</Link>
                      <Link to={isAuth == true ? "/" : "/login"}>{t("SIGN_IN")} </Link>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-3 col-sm-6">
                    <div className="footer-links-card">
                      <h3>{t("INSTRUMENTS")}</h3>
                      <Link to="/cms/platform-mt5">{t('MT5_PLATFORM')} </Link>
                      <Link to="/cms/platform-webtrader">{t("WEBTRADER_PLATFORM")}</Link>
                      <Link to="/cms/platform-mobile">{t("MOBILE_PLATFORM")} </Link>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-3 col-sm-6">
                    <div className="footer-links-card">
                      <h3>{t("FX_PAYMENTS")}</h3>
                      <Link to="/account-types">{t("ACCOUNT_TYPES")}</Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-12">
              <div className="footer-about-card">
                <p className="footer_abt_content">{t("FOOTER")} </p>
                <Link className="navbar-brand" to="/">
                  {theme === "light" ? (
                    <img src={logo} className="img-fluid" alt="Light logo" />
                  ) : (
                    <img src={logo_dark} className="img-fluid" alt="Dark logo" />
                  )}
                </Link>
                <p className="footer_abt_content">
                  {t("FOOTER_CONTENT")}
                  {/* "Stakonomics" and Stakonomics's family of trademarks are owned by Stakonomics Corporation. All other trademarks appearing on this website are the property of their respective owners. */}
                </p>

              </div>
            </div>

          </div>
        </div>
        <div className="footer_panel_bottom">
          <p>
            <Trans
              i18nKey="COPY_RIGHTS"
              components={{ a: <a href="/" /> }}
              values={{ year: new Date().getFullYear(), title: 'Stakonomics Corporation' }}
            />
          </p>
          <ul className="footer_menu_links">
            <li>
              <Link to="/terms">{t("TERMS_OF_SERVICE")}</Link>
            </li>
            <li>
              <Link to="/privacy">{t("PRIVACY_POLICY")}</Link>
            </li>
          </ul>
        </div>
      </div>
    </footer>
  );
}
