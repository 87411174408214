import React, { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import CurrencyFormat from 'react-currency-format';
import { useTranslation } from "react-i18next";

// import api
import { getOrderList, StakeCancelOrder } from "../../api/staking";

// import lib
import { dateTimeFormat } from "../../lib/dateTimeHelper";
import { truncateDecimals } from "../../lib/roundOf";
import { toastAlert } from "../../lib/toastAlert"



const OrderHistory = () => {

  const { t, i18n } = useTranslation()


  // History Table Data
  const columns = [
    {
      name: t("DATE"),
      selector: (row) => dateTimeFormat(row.createdAt),
    },
    {
      name: t("COIN"),
      selector: (row) => row.coin,

    },
    {
      name: t("APY"),
      selector: (row) => row.APY + " %",
    },
    {
      name: t("TYPE"),
      cell: (record) => {
        let data = record.type == "flexible" ? t("FLEXIBLE") : t("FIXED")
        return (
          <>{data}</>
        )
      }
    },
    {
      name: t("AMOUNT"),
      cell: (record) => {
        return <> <CurrencyFormat displayType={'text'} thousandSeparator={true} value={truncateDecimals(record?.amount, record.decimals)} />
          &nbsp;<span>{record?.coin}</span>
        </>
      },
    },
    {
      name: t("REMAINING_DAYS"),
      cell: (record) => {
        let date = new Date()
        const copy = new Date(Number(date))
        let rewdDate = date.getDate() + record.duration
        copy.setDate(date.getDate() + record.duration)
        // moment(copy).format('YYYY-MM-DD')
        if (record.status == "active") {
          return <span>{record.duration}</span>
        } else {
          return <span>-</span>
        }
      },
    },
    {
      name: "Action",
      cell: (record) => {
        let redemStatus = record.redemStatus
        if (record.status == "active") redemStatus = "pending"
        if (record.type == "flexible" && record.status == "active") {
          return (
            <>
              <button className="btn btn-light btn-outline py-0 ml-0"
                onClick={(value) => cancelOrder(value, record._id)}
              >
                {t("RELEASE")}
              </button >
            </>
          )
        } else if (redemStatus != "pending") {
          return (
            <span>{t("REDEMPTION_PERIOD")} {record.redemptionPeriod} {t("DAY")}</span>
          )
        } else {
          return (
            <span>-</span>
          )
        }
      },
    },
  ];

  // state
  const [data, setData] = useState([]);
  const [count, setCount] = useState(0);
  const [pageDoc, setPageDoc] = useState({ page: 1, limit: 20 });
  const [loader, setLoader] = useState(false)
  const { page, limit } = pageDoc;

  //function
  const getData = async () => {
    try {
      const { status, result } = await getOrderList({ page, limit });
      if (status === "success") {
        setData(result);
        setCount(result.length);
      }
    } catch (err) {
      console.log(err, 'error')
    }
  };


  const cancelOrder = async (e, item) => {
    e.preventDefault();
    let text = t("RELEASE_STAKE");
    if (window.confirm(text) != true) {
      return
    }
    setLoader(true)
    try {
      const { status, result, message } = await StakeCancelOrder(item);
      if (status) {
        getData();
        toastAlert("success", t(message), "login");
        setLoader(false);
      }
    } catch (err) {
      setLoader(false)
      console.log(err, "errerrerrerr")
    }
  }


  useEffect(() => {
    getData();
  }, [page, limit]);

  const customStyles = {
    rows: {
      style: {
        minHeight: '72px', // override the row height
      },
    },
    headCells: {
      style: {
        paddingLeft: '8px', // override the cell padding for head cells
        paddingRight: '8px',
      },
    },
    cells: {
      style: {
        paddingLeft: '8px', // override the cell padding for data cells
        paddingRight: '8px',
      },
    },
  };

  const customPaginationOptions = {
    rowsPerPageText: t('ROWS_PER_PAGE'),
    rangeSeparatorText: t('OF'),
  };

  return (
    <>
      <div className="inner_title_wrapper">
        <h2 className="inner_title">{t("STAKING")}</h2>
      </div>
      <div className="dash_box">
        <div className="primary_datatable">
          <DataTable
            columns={columns}
            data={data}
            pagination
            paginationServer
            paginationTotalRows={count}
            paginationPerPage={limit}
            paginationRowsPerPageOptions={[20, 30, 40, 50]}
            onChangePage={(page) =>
              setPageDoc({ ...pageDoc, ...{ page: page } })
            }
            onChangeRowsPerPage={(limit) =>
              setPageDoc({ ...pageDoc, ...{ page: 1, limit: limit } })
            }
            noDataComponent={t('THERE_NO_RECORD')}
            paginationComponentOptions={customPaginationOptions}

          // customStyles={customStyles}
          />
        </div>
      </div>
    </>
  );
};

export default OrderHistory;
