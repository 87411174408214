import React, { useEffect, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import { useSelector } from "react-redux";
import logo from "../assets/images/logo.svg";
import logo_dark from "../assets/images/logo_dark.svg";
import $ from "jquery";
import ThemeToggle from "./ThemeToggle";
import { useTheme } from "./ThemeContext";
import { useTranslation } from "react-i18next";
import { Dropdown, Image } from "react-bootstrap";
import { getLang, setLang } from "../lib/localStorage";
import isEmpty from "../lib/isEmpty";

export default function Navbar(props) {
  // dispatch
  const { isAuth } = useSelector((state) => state.auth);
  const { theme } = useTheme();
  const { t, i18n } = useTranslation();

  function loadScript() {
    // Navbar Sticky
    var t = $(".navbar-sticky");
    $(window).on("scroll load", function (e) {
      var e = $(window).scrollTop();
      120 <= e
        ? t.addClass("navbar-sticky--moved-up")
        : t.removeClass("navbar-sticky--moved-up");
      250 <= e
        ? t.addClass("navbar-sticky--transitioned")
        : t.removeClass("navbar-sticky--transitioned");
      500 <= e
        ? t.addClass("navbar-sticky--on")
        : t.removeClass("navbar-sticky--on");
    });

    $(document).ready(function () {
      $(".auth_btn_grp .primary_btn").click(function () {
        $("body").css({ overflow: "unset" });
      });
    });
  }

  const langList = [
    {
      value: "en",
      image: require("../assets/images/EN.png"),
      label: "English",
    },
    { value: "ar", image: require("../assets/images/AR.png"), label: "Arabic" },
    { value: "fr", image: require("../assets/images/FR.png"), label: "French" },
    {
      value: "sp",
      image: require("../assets/images/SP.png"),
      label: "Spanish",
    },
  ];

  const [defaultLang, setDefaultLang] = useState({
    value: "en",
    image: require("../assets/images/EN.png"),
    label: "English",
  });

  const [language, setLanguage] = useState("en");

  const handleLanguage = (lang, option) => {
    setLang(lang);
    setLanguage(lang);
    setDefaultLang(option);
    i18n.changeLanguage(lang);
  };

  useEffect(() => {
    let langCode = getLang();

    if (isEmpty(langCode)) {
      setLang(language);
      i18n.changeLanguage(language);
    } else {
      setLang(langCode);
      i18n.changeLanguage(langCode);
      const selectedLang = langList.find((item) => item.value === langCode);
      if (selectedLang) setDefaultLang(selectedLang);
      setLanguage(langCode);
    }
  }, [language]);

  useEffect(() => {
    loadScript();
  }, []);

  return (
    <>
      <div className="container-fluid">
        <nav className="navbar navbar-dark navbar-expand-lg main_navbar navbar-sticky">
          <Link className="navbar-brand" to="/">
            {theme === "light" ? (
              <img
                src={logo}
                className="img-fluid brand_logo"
                alt="Light logo"
              />
            ) : (
              <img
                src={logo_dark}
                className="img-fluid brand_logo"
                alt="Dark logo"
              />
            )}
          </Link>
          {/* <ul className="navbar-nav navbar_left">
          <li className="nav-item">
            <NavLink className="nav-link" aria-current="page" to="/staking">
              Staking
            </NavLink>
          </li>
        </ul> */}
          <div className="navbar_right">
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="offcanvas"
              data-bs-target="#offcanvasNavbar"
              aria-controls="offcanvasNavbar"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            <div
              className="offcanvas offcanvas-end"
              tabIndex={-1}
              id="offcanvasNavbar"
              aria-labelledby="offcanvasNavbarLabel"
            >
              <div className="offcanvas-header">
                <h5 className="offcanvas-title" id="offcanvasNavbarLabel"></h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="offcanvas"
                  aria-label="Close"
                ></button>
              </div>
              {!isAuth && (
                <div className="offcanvas-body">
                  <div className="ms-auto">
                    <div className="auth_btn_grp">
                      <Link to="/login" className="primary_btn">
                        {t("LOGIN")}
                      </Link>
                      <Link to="/register" className="primary_btn">
                        {t("REGISTER")}
                      </Link>
                    </div>
                  </div>
                </div>
              )}
            </div>
            <ThemeToggle />

            <button
              type="button"
              class="lang_btn"
              data-bs-toggle="modal"
              data-bs-target="#exampleModal"
            >
              <Image src={defaultLang.image} width={24} height={24} />
            </button>

            {/* <div className="form-group">
              <Dropdown className="image_dropdown_wrapper" drop="down">
                <Dropdown.Toggle id="dropdown-basic" className="image_dropdown">
                  <Image src={defaultLang.image} width={40} height={38} />{" "}
                  {defaultLang.label}
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  {langList.map((option, i) => (
                    <Dropdown.Item
                      key={i}
                      onClick={() => handleLanguage(option.value, option)}
                    >
                      <Image src={option.image} width={40} height={38} />{" "}
                      {option?.label}
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
            </div> */}
          </div>
        </nav>
      </div>

      {/* Change Language Modal */}
      <div
        class="modal fade"
        id="exampleModal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered modal-sm">
          <div class="modal-content">
            <div class="modal-header">
              <h1 class="modal-title fs-5" id="exampleModalLabel">
                Change Language
              </h1>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body">
              <ul className="flag_list">
                {langList.map((option, i) => (
                  <li
                    key={i}
                    onClick={() => handleLanguage(option.value, option)}
                    data-bs-dismiss="modal" className={language === option.value ? 'active' : ''}
                  >
                    <span>{option?.label}</span>
                    <Image src={option.image} width={24} height={24} />
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
