import React, { useEffect , useState } from "react";
import Footer from "../components/Footer.js";
import Navbar from "../components/Navbar.js";

// Images
import copy_icon from "../assets/images/copy_icon.svg";
import { getCMSPage } from "../api/users.js";

// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
  
}




export default function Terms(props) {

  const [content, setContent] = useState('');

// function
const fetchCmsPage = async () => {
  try {
    const { status, loading, result } = await getCMSPage('terms');
    if (status == 'success') {
      // console.log('result', result)
      setContent(result)
      document.title = result.title;
      const el = document.querySelector("meta[name='metadescription']");
      el.setAttribute("content", result.metadescription);


      const els = document.querySelector("meta[name='keywords']");
      els.setAttribute("content", result.metakeywords);
    }
  } catch (err) { }
}

useEffect(() => {
  fetchCmsPage()
}, [])

console.log(content,'contentcontent')
  return (
    <div>
      <ScrollToTopOnMount />
      <Navbar />
      <div className="innerpages_wrapper">
        <div className="container">
          <div className="dash_wrapper">
            <div className="row">
              <div className="col-xl-12">
                
              <div
                  dangerouslySetInnerHTML={{
                    __html: content && content.content,
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>    
  );
}
