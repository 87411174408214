import React, { useState, useEffect, useContext } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { getOrderBook } from "../../api/tradeAction";
import SocketContext from "../../context/SocketContext";
import isEmpty from "../../lib/isEmpty";
import { toFixed } from "../../lib/roundOf";


export default function OrderBook() {
  const [view, setView] = useState("all");
  const pairData = useSelector((state) => state.tradePair);
  const marketPrice = useSelector((state) => state.marketPrice)

  const precentage = 50;

  const socketContext = useContext(SocketContext);
  const { t, i18n } = useTranslation()

  const [orderBook, setOrderBook] = useState({
    "buyOrder": [],
    "sellOrder": [],
  })
  let { buyOrder, sellOrder } = orderBook
  // function
  const fetchOrderBook = async (pairId) => {
    try {
      const { status, result } = await getOrderBook(pairId);

      if (status == 'success') {
        setOrderBook({
          sellOrder: result.sellOrder,
          buyOrder: result.buyOrder
        })
      }
    } catch (err) { }
  }

  useEffect(() => {
    if (!isEmpty(pairData.pairId) && pairData.botstatus == 'off'
    ) {
      fetchOrderBook(pairData.pairId)
    }
    // setOrderBook({
    //   buyOrder: [],
    //   sellOrder: [],
    // });


    // socket
    socketContext.socket.on('orderBook', (result) => {
      // console.log(result, "------socketContext.socket")

      if (result.pairId == pairData.pairId) {
        setOrderBook({
          'buyOrder': result.buyOrder,
          'sellOrder': result.sellOrder,
        })
      }
    })

    return () => {
      // setOrderBook({
      //   buyOrder: [],
      //   sellOrder: [],
      // });
      socketContext.socket.off("orderBook");
    }
  }, [pairData])


  const getpercentage = (item, type) => {
    let buyordeindex = 0

    if (type == 'buy') {
      const le = buyOrder.length;
      buyordeindex = buyOrder[le - 1].total;
    } else if (type == 'sell') {
      const le = sellOrder.length;
      buyordeindex = sellOrder[le - 1].total;
    }
    var precentage = (item.total / buyordeindex) * 100;
    return `${parseInt(precentage)}`;
  }

  // console.log(pairData, "-------buyOrder", sellOrder)

  return (
    <>
      <div className={"orderbook_wrap dash_box"}>
        <div className={"orderbook_header"}>
          <h6 className={"spot_head"}>{t("ORDER_BOOK")}</h6>
          {/* <div className={"orderbook_header_tips"}>
            <button onClick={() => setView("all")}>
              all
            </button>
            <button onClick={() => setView("sell")}>
              sel
            </button>
            <button onClick={() => setView("buy")}>
              buy
            </button>
          </div> */}
        </div>
        <div className={"orderbook_wrap_inner"} id="spotOrderbook">
          <div className={"orderbook_tbheader"}>
            <div className={"content"}>
              <div
                className={"item"}
                style={{ justifyContent: "flex-start" }}
              >
                {t("PRICE")}
              </div>
              <div className={"item"} style={{ justifyContent: "flex-end" }}>
                {t("AMOUNT")}
              </div>
              <div className={"item"} style={{ justifyContent: "flex-end" }}>
              {t("TOTAL")}
              </div>
            </div>
          </div>

          <div
            className={`${"orderlist_container"}`}
          >
            {(view == "sell" || view == "all") && (
              <div className={`${"orderbook_list"} ${"orderbook_ask"}`}>
                <div className={"orderbook_list_container"}>
                  {sellOrder &&
                    sellOrder.length > 0 &&
                    sellOrder.slice(0, 7).map((item, key) => {
                      return (
                        <div className={"orderbook_progress"}  >
                          <div className={"progress_container"}>
                            <div className={"row_content"}>
                              <div className={"ask_light"}>
                                {toFixed(item._id, pairData.secondFloatDigit)}
                              </div>
                              <div className={"text"}>
                                {toFixed(item.quantity, pairData.firstFloatDigit)}
                              </div>
                              <div className={"text"}>
                                {toFixed(item._id * item.quantity, pairData.firstFloatDigit)}
                              </div>
                              <div
                                className={"orderbook_tbody_row_bar_sell"}
                                style={{ width: `${getpercentage(item, 'sell')}%` }}
                              ></div>
                            </div>
                            <div
                              className={`${"progress_bar"} ${"ask_bar"}`}
                              style={{
                                transform: "translateX(-10.385%)",
                              }}
                            ></div>
                          </div>
                        </div>
                      )
                    })}
                </div>
              </div>
            )}

            <div className={"orderbook_ticker"}>
              <div className={`${"contractPrice"}`}>
                {marketPrice?.markPrice}
              </div>
            </div>
            {(view == "buy" || view == "all") && (
              <div className={`${"orderbook_list"} ${"orderbook_bid"}`}>
                <div className={"orderbook_list_container"}>
                  {buyOrder &&
                    buyOrder.length > 0 &&
                    buyOrder.slice(0, 7).map((item, key) => {
                      return (
                        <div className={"orderbook_progress"} >
                          <div className={"progress_container"}>
                            <div className={"row_content"}>
                              <div className={"bid_light"}>
                                {toFixed(item._id, pairData.secondFloatDigit)}
                              </div>
                              <div className={"text"}>
                                {toFixed(item.quantity, pairData.firstFloatDigit)}
                              </div>
                              <div className={"text"}>
                                {toFixed(item._id * item.quantity, pairData.firstFloatDigit)}
                              </div>
                              <div
                                className={"orderbook_tbody_row_bar_buy"}
                                style={{ width: `${getpercentage(item, 'buy')}%` }}
                              ></div>
                            </div>
                            <div
                              className={`${"progress_bar"} ${"bid_bar"}`}
                              style={{
                                transform: "translateX(-10.385%)",
                              }}
                            ></div>
                          </div>
                        </div>
                      )
                    })}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
