import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import DataTable from "react-data-table-component";
import SocketContext from "../../context/SocketContext";

//IMPORT LIB
import { toFixedDown } from "../../lib/roundOf";





const ListTable = (props) => {

    const { t, i18n } = useTranslation()
    const socketContext = useContext(SocketContext);


    //PROPS
    const { duppairlists, setduppairlists } = props


    //STATE
    const [tablecontent, setContent] = useState("first")
    const [pageDoc, setPageDoc] = useState({ page: 1, limit: 30 });
    const { page, limit } = pageDoc;




    const columns = [
        {
            name: t("NAME"),
            selector: (row) => <div className="market_pair">
                <img
                    alt="img"
                    className="img-fluid"
                    src={row?.image}
                />
                <p>
                    {row?.firstCurrencySymbol
                        + "/" + row?.secondCurrencySymbol} <span>{row?.currencyName}</span>
                </p>
            </div>,
        },
        {
            name: t("QUANTITY"),
            selector: (row) => <span>{toFixedDown(row?.hedgeQty, 8)}</span>,
        },
        {
            name: t("PRICE"),
            selector: (row) => <> $ {toFixedDown(row?.markPrice, 8)}</>,
        },
        {
            name: t("24_CHANGE"),
            selector: (row) => <span className={row?.change < 0 ? "change_badge badge_red" : "change_badge badge_green"}>{row?.change}%</span>
        },
        {
            name: t("24_HIGH"),
            selector: (row) => <> $ {toFixedDown(row?.high, 8)}</>,
        },
        {
            name: t("24_LOW"),
            selector: (row) => <> $ {toFixedDown(row?.low, 8)}</>,
        },
        {
            name: t("TOTAL"),
            selector: (row) => <> $ {toFixedDown(row?.hedgeQty * row?.markPrice, 2)}</>,
        },
    ];



    useEffect(() => {
        if (tablecontent == "first") {
            socketContext.socket.on("marketPrice", (result) => {
                // console.log(result,"-------result")
                setduppairlists((el) => {
                    let pairList = [];
                    el.map((item) => {
                        if (item._id == result.pairId) {
                            pairList.push({
                                ...item,
                                ...{
                                    markPrice: result.data.markPrice,
                                    change: result.data.change,
                                },
                            });
                        } else {
                            pairList.push(item);
                        }
                    });

                    return pairList;
                });
            });
        }
    }, []);


    const customPaginationOptions = {
        rowsPerPageText: t('ROWS_PER_PAGE'),
        rangeSeparatorText: t('OF'),
      };

    return (
        <>

            <div className="primary_datatable">
                <DataTable columns={columns} data={duppairlists}
                    pagination
                    paginationPerPage={limit}
                    paginationRowsPerPageOptions={[30, 40, 50, 60]}
                    onChangePage={(page) =>
                        setPageDoc({ ...pageDoc, ...{ page: page } })
                    }
                    onChangeRowsPerPage={(limit) =>
                        setPageDoc({ ...pageDoc, ...{ page: 1, limit: limit } })
                    } 
                    noDataComponent={t('THERE_NO_RECORD')}
                    paginationComponentOptions={customPaginationOptions}
                    />
            </div>
        </>
    )




}


export default ListTable;