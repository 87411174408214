import React, { useState, useRef } from 'react';
import { useTranslation } from "react-i18next";

// import api
import { changePassword } from '../../api/users';

// import lib
import { toastAlert } from '../../lib/toastAlert';
import isEmpty from '../../lib/isEmpty';

//import validation
import { changePwdValidate } from './validation';

const initialFormValue = {
  "oldPassword": "",
  "password": "",
  "confirmPassword": ""
}

const initialPassWordIcon = {
  "oldPasswordIcon": false,
  "passwordIcon": false,
  "confirmPasswordIcon": false
}

const ChangePassword = () => {

  const modalRef = useRef();
  const { t, i18n } = useTranslation()


  //state
  const [formValue, setFormValue] = useState(initialFormValue);
  const [passwordIcons, setPasswordIcons] = useState(initialPassWordIcon);
  const [errors, setErrors] = useState({});
  const [loader, setLoader] = useState(false);
  const { oldPassword, password, confirmPassword } = formValue;
  const { oldPasswordIcon, passwordIcon, confirmPasswordIcon } = passwordIcons;

  //function
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValue({ ...formValue, ... { [name]: value } })
    if (!isEmpty(errors)) {
      setErrors({});
    }
  }

  const handlePassIcon = (name) => setPasswordIcons({ ...passwordIcons, ... { [name]: !passwordIcons[name] } })

  const handleCloseModal = () => {
    setErrors({})
    setFormValue(initialFormValue)
    setPasswordIcons(initialPassWordIcon)
    modalRef.current.click()
  };

  const handleSubmit = async () => {
    try {
      setLoader(true);
      let reqData = {
        oldPassword, password, confirmPassword
      };
      let validationError = changePwdValidate(reqData);
      if (!isEmpty(validationError)) {
        setErrors(validationError);
        setLoader(false);
        return;
      }
      const { status, loading, message, errors } = await changePassword({ oldPassword, password, confirmPassword });
      setLoader(loading);
      if (status === 'success') {
        setLoader(false);
        toastAlert('success', t(message), 'changePassword')
        handleCloseModal()
      } else {
        setLoader(false);
        if (errors) {
          setErrors(errors)
        } else {
          toastAlert('error', t(message), 'changePassword')
        }
      }
    } catch (err) {
      setLoader(false);
      console.log(err, "-error")
    }
  }

  return (
    <div
      className="modal fade primary_modal"
      data-bs-backdrop="static"
      id="changepassword_modal"
      tabindex="-1"
      aria-labelledby="changepassword_modalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header">
            <h1 className="modal-title" id="changepassword_modalLabel">
              {t("CHANGE_PASSWORD")}
            </h1>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              ref={modalRef}
              onClick={handleCloseModal}
            ></button>
          </div>
          <div className="modal-body">
            <form className="assets_form">
              <div className="mb-3">
                <label for="oldpassword" className="form-label">
                  {t("OLD_PASSWORD")}
                </label>
                <div className="asset_deposit_grp">
                  <input
                    type={!oldPasswordIcon ? "password" : "text"}
                    className="form-control primary_asset_inp"
                    name="oldPassword"
                    placeholder={t("OLD_PASSWORD_PLACEHOLDER")}
                    value={oldPassword}
                    onChange={handleChange}
                  />
                  <span className="withdrawal_type">
                    <i class={oldPasswordIcon ? "bi bi-eye-fill" : "bi bi-eye-slash-fill"} onClick={() => handlePassIcon('oldPasswordIcon')}></i>
                  </span>
                </div>
                <span className={'text-danger'} >{t(errors?.oldPassword)}</span>
              </div>

              <div className="mb-3">
                <label for="newpassword" className="form-label">
                  {t("NEW_PASSWORD")}
                </label>
                <div className="asset_deposit_grp">
                  <input
                    type={!passwordIcon ? "password" : "text"}
                    className="form-control primary_asset_inp"
                    name="password"
                    placeholder={t("NEW_PASSWORD_PLACEHOLDER")}
                    value={password}
                    onChange={handleChange}
                  />
                  <span className="withdrawal_type">
                    <i class={passwordIcon ? "bi bi-eye-fill" : "bi bi-eye-slash-fill"} onClick={() => handlePassIcon('passwordIcon')}></i>
                  </span>
                </div>
                <span className={'text-danger'} >{t(errors?.password)}</span>
              </div>

              <div className="mb-3">
                <label for="cnewpassword" className="form-label">
                  {t("CONFIRM_PASSWORD")}
                </label>
                <div className="asset_deposit_grp">
                  <input
                    type={!confirmPasswordIcon ? "password" : "text"}
                    className="form-control primary_asset_inp"
                    name="confirmPassword"
                    placeholder={t("CONFIRM_PASSWORD_PLACEHOLDER")}
                    value={confirmPassword}
                    onChange={handleChange}
                  />
                  <span className="withdrawal_type">
                    <i class={confirmPasswordIcon ? "bi bi-eye-fill" : "bi bi-eye-slash-fill"} onClick={() => handlePassIcon('confirmPasswordIcon')}></i>
                  </span>
                </div>
                <span className={'text-danger'} >{t(errors?.confirmPassword)}</span>
              </div>

              <div className="asset_modal_btn_grp mt-4 mb-4">
                <button
                  className="w-100 primary_btn primary_btn_transform"
                  type="button"
                  onClick={handleCloseModal}
                >
                  {t("CANCEL")}
                </button>
                <button
                  className="w-100 primary_btn primary_btn_with_bg primary_btn_transform"
                  type="button"
                  onClick={handleSubmit}
                >
                  {t("CHANGE")}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ChangePassword;